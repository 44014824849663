@import 'breakpoints';

.embla {
  position: relative;
}

.embla__viewport {
  overflow: visible;

  @media (min-width: $mui-sm) {
    overflow: hidden;
  }
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
}

.embla__slide {
  flex: 1;
  min-width: 0;
  position: relative;
}

.embla__navigation-prev-button {
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.embla__navigation-next-button {
  right: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.embla__pagination {
  align-items: center;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
}
