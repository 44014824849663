@import 'dimens';
@import 'breakpoints';

.mt-1,
.my-1 {
  margin-top: $Space-1 !important;
}

.mb-1,
.my-1 {
  margin-bottom: $Space-1 !important;
}

.ml-1,
.mx-1 {
  margin-left: $Space-1 !important;
}

.mr-1,
.mx-1 {
  margin-right: $Space-1 !important;
}

.mt-2,
.my-2 {
  margin-top: $Space-2 !important;
}

.mb-2,
.my-2 {
  margin-bottom: $Space-2 !important;
}

.ml-2,
.mx-2 {
  margin-left: $Space-2 !important;
}

.mr-2,
.mx-2 {
  margin-right: $Space-2 !important;
}

.mt-3,
.my-3 {
  margin-top: $Space-3 !important;
}

.mb-3,
.my-3 {
  margin-bottom: $Space-3 !important;
}

.ml-3,
.mx-3 {
  margin-left: $Space-3 !important;
}

.mr-3,
.mx-3 {
  margin-right: $Space-3 !important;
}

.mt-4,
.my-4 {
  margin-top: $Space-4 !important;
}

.mb-4,
.my-4 {
  margin-bottom: $Space-4 !important;
}

.ml-4,
.mx-4 {
  margin-left: $Space-4 !important;
}

.mr-4,
.mx-4 {
  margin-right: $Space-4 !important;
}

.mt-5,
.my-5 {
  margin-top: $Space-5 !important;
}

.mb-5,
.my-5 {
  margin-bottom: $Space-5 !important;
}

.ml-5,
.mx-5 {
  margin-left: $Space-5 !important;
}

.mr-5,
.mx-5 {
  margin-right: $Space-5 !important;
}

.mt-6,
.my-6 {
  margin-top: $Space-6 !important;
}

.mb-6,
.my-6 {
  margin-bottom: $Space-6 !important;
}

.ml-6,
.mx-6 {
  margin-left: $Space-6 !important;
}

.mr-6,
.mx-6 {
  margin-right: $Space-6 !important;
}

.mt-7,
.my-7 {
  margin-top: $Space-7 !important;
}

.mb-7,
.my-7 {
  margin-bottom: $Space-7 !important;
}

.ml-7,
.mx-7 {
  margin-left: $Space-7 !important;
}

.mr-7,
.mx-7 {
  margin-right: $Space-7 !important;
}

.mt-8,
.my-8 {
  margin-top: $Space-8 !important;
}

.mb-8,
.my-8 {
  margin-bottom: $Space-8 !important;
}

.ml-8,
.mx-8 {
  margin-left: $Space-8 !important;
}

.mr-8,
.mx-8 {
  margin-right: $Space-8 !important;
}

.mt-9,
.my-9 {
  margin-top: $Space-9 !important;
}

.mb-9,
.my-9 {
  margin-bottom: $Space-9 !important;
}

.ml-9,
.mx-9 {
  margin-left: $Space-9 !important;
}

.mr-9,
.mx-9 {
  margin-right: $Space-9 !important;
}

.mt-10,
.my-10 {
  margin-top: $Space-10 !important;
}

.mb-10,
.my-10 {
  margin-bottom: $Space-10 !important;
}

.ml-10,
.mx-10 {
  margin-left: $Space-10 !important;
}

.mr-10,
.mx-10 {
  margin-right: $Space-10 !important;
}

.mt-11,
.my-11 {
  margin-top: $Space-11 !important;
}

.mb-11,
.my-11 {
  margin-bottom: $Space-11 !important;
}

.ml-11,
.mx-11 {
  margin-left: $Space-11 !important;
}

.mr-11,
.mx-11 {
  margin-right: $Space-11 !important;
}

.mt-12,
.my-12 {
  margin-top: $Space-12 !important;
}

.mb-12,
.my-12 {
  margin-bottom: $Space-12 !important;
}

.ml-12,
.mx-12 {
  margin-left: $Space-12 !important;
}

.mr-12,
.mx-12 {
  margin-right: $Space-12 !important;
}

.mt-13,
.my-13 {
  margin-top: $Space-13 !important;
}

.mb-13,
.my-13 {
  margin-bottom: $Space-13 !important;
}

.ml-13,
.mx-13 {
  margin-left: $Space-13 !important;
}

.mr-13,
.mx-13 {
  margin-right: $Space-13 !important;
}

.mt-14,
.my-14 {
  margin-top: $Space-14 !important;
}

.mb-14,
.my-14 {
  margin-bottom: $Space-14 !important;
}

.ml-14,
.mx-14 {
  margin-left: $Space-14 !important;
}

.mr-14,
.mx-14 {
  margin-right: $Space-14 !important;
}

.mt-15,
.my-15 {
  margin-top: $Space-15 !important;
}

.mb-15,
.my-15 {
  margin-bottom: $Space-15 !important;
}

.ml-15,
.mx-15 {
  margin-left: $Space-15 !important;
}

.mr-15,
.mx-15 {
  margin-right: $Space-15 !important;
}

.m-1 {
  margin: $Space-1;
}

.m-2 {
  margin: $Space-2;
}

.m-3 {
  margin: $Space-3;
}

.m-4 {
  margin: $Space-4;
}

.m-5 {
  margin: $Space-5;
}

.m-6 {
  margin: $Space-6;
}

.m-7 {
  margin: $Space-7;
}

.m-8 {
  margin: $Space-8;
}

.m-9 {
  margin: $Space-9;
}

.m-10 {
  margin: $Space-10;
}

.m-11 {
  margin: $Space-11;
}

.m-12 {
  margin: $Space-12;
}

.m-13 {
  margin: $Space-13;
}

.m-14 {
  margin: $Space-14;
}

.m-15 {
  margin: $Space-15;
}

.pt-1,
.py-1 {
  padding-top: $Space-1 !important;
}

.pb-1,
.py-1 {
  padding-bottom: $Space-1 !important;
}

.pl-1,
.px-1 {
  padding-left: $Space-1 !important;
}

.pr-1,
.px-1 {
  padding-right: $Space-1 !important;
}

.pt-2,
.py-2 {
  padding-top: $Space-2 !important;
}

.pb-2,
.py-2 {
  padding-bottom: $Space-2 !important;
}

.pl-2,
.px-2 {
  padding-left: $Space-2 !important;
}

.pr-2,
.px-2 {
  padding-right: $Space-2 !important;
}

.pt-3,
.py-3 {
  padding-top: $Space-3 !important;
}

.pb-3,
.py-3 {
  padding-bottom: $Space-3 !important;
}

.pl-3,
.px-3 {
  padding-left: $Space-3 !important;
}

.pr-3,
.px-3 {
  padding-right: $Space-3 !important;
}

.pt-4,
.py-4 {
  padding-top: $Space-4 !important;
}

.pb-4,
.py-4 {
  padding-bottom: $Space-4 !important;
}

.pl-4,
.px-4 {
  padding-left: $Space-4 !important;
}

.pr-4,
.px-4 {
  padding-right: $Space-4 !important;
}

.pt-5,
.py-5 {
  padding-top: $Space-5 !important;
}

.pb-5,
.py-5 {
  padding-bottom: $Space-5 !important;
}

.pl-5,
.px-5 {
  padding-left: $Space-5 !important;
}

.pr-5,
.px-5 {
  padding-right: $Space-5 !important;
}

.pt-6,
.py-6 {
  padding-top: $Space-6 !important;
}

.pb-6,
.py-6 {
  padding-bottom: $Space-6 !important;
}

.pl-6,
.px-6 {
  padding-left: $Space-6 !important;
}

.pr-6,
.px-6 {
  padding-right: $Space-6 !important;
}

.pt-7,
.py-7 {
  padding-top: $Space-7 !important;
}

.pb-7,
.py-7 {
  padding-bottom: $Space-7 !important;
}

.pl-7,
.px-7 {
  padding-left: $Space-7 !important;
}

.pr-7,
.px-7 {
  padding-right: $Space-7 !important;
}

.pt-8,
.py-8 {
  padding-top: $Space-8 !important;
}

.pb-8,
.py-8 {
  padding-bottom: $Space-8 !important;
}

.pl-8,
.px-8 {
  padding-left: $Space-8 !important;
}

.pr-8,
.px-8 {
  padding-right: $Space-8 !important;
}

.pt-9,
.py-9 {
  padding-top: $Space-9 !important;
}

.pb-9,
.py-9 {
  padding-bottom: $Space-9 !important;
}

.pl-9,
.px-9 {
  padding-left: $Space-9 !important;
}

.pr-9,
.px-9 {
  padding-right: $Space-9 !important;
}

.pt-10,
.py-10 {
  padding-top: $Space-10 !important;
}

.pb-10,
.py-10 {
  padding-bottom: $Space-10 !important;
}

.pl-10,
.px-10 {
  padding-left: $Space-10 !important;
}

.pr-10,
.px-10 {
  padding-right: $Space-10 !important;
}

.pt-11,
.py-11 {
  padding-top: $Space-11 !important;
}

.pb-11,
.py-11 {
  padding-bottom: $Space-11 !important;
}

.pl-11,
.px-11 {
  padding-left: $Space-11 !important;
}

.pr-11,
.px-11 {
  padding-right: $Space-11 !important;
}

.pt-12,
.py-12 {
  padding-top: $Space-12 !important;
}

.pb-12,
.py-12 {
  padding-bottom: $Space-12 !important;
}

.pl-12,
.px-12 {
  padding-left: $Space-12 !important;
}

.pr-12,
.px-12 {
  padding-right: $Space-12 !important;
}

.pt-13,
.py-13 {
  padding-top: $Space-13 !important;
}

.pb-13,
.py-13 {
  padding-bottom: $Space-13 !important;
}

.pl-13,
.px-13 {
  padding-left: $Space-13 !important;
}

.pr-13,
.px-13 {
  padding-right: $Space-13 !important;
}

.pt-14,
.py-14 {
  padding-top: $Space-14 !important;
}

.pb-14,
.py-14 {
  padding-bottom: $Space-14 !important;
}

.pl-14,
.px-14 {
  padding-left: $Space-14 !important;
}

.pr-14,
.px-14 {
  padding-right: $Space-14 !important;
}

.pt-15,
.py-15 {
  padding-top: $Space-15 !important;
}

.pb-15,
.py-15 {
  padding-bottom: $Space-15 !important;
}

.pl-15,
.px-15 {
  padding-left: $Space-15 !important;
}

.pr-15,
.px-15 {
  padding-right: $Space-15 !important;
}

.p-1 {
  padding: $Space-1;
}

.p-2 {
  padding: $Space-2;
}

.p-3 {
  padding: $Space-3;
}

.p-4 {
  padding: $Space-4;
}

.p-5 {
  padding: $Space-5;
}

.p-6 {
  padding: $Space-6;
}

.p-7 {
  padding: $Space-7;
}

.p-8 {
  padding: $Space-8;
}

.p-9 {
  padding: $Space-9;
}

.p-10 {
  padding: $Space-10;
}

.p-11 {
  padding: $Space-11;
}

.p-12 {
  padding: $Space-12;
}

.p-13 {
  padding: $Space-13;
}

.p-14 {
  padding: $Space-14;
}

.p-15 {
  padding: $Space-15;
}

@media (min-width: $bootstrap-xl) {
}

@media (min-width: $bootstrap-lg) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mx-lg-6 {
    margin-left: $Space-6 !important;
  }

  .mx-lg-6 {
    margin-right: $Space-6 !important;
  }

  .p-lg-12 {
    padding: $Space-12 !important;
  }
}

@media (min-width: $bootstrap-md) {
  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-4 {
    margin-top: $Space-4 !important;
  }
}

@media (min-width: $bootstrap-sm) {
  .pl-sm-3 {
    padding-left: $Space-3 !important;
  }

  .ml-sm-4 {
    margin-left: $Space-4 !important;
  }
}

@media (min-width: $bootstrap-xs) {
  .mb-xs-4 {
    margin-bottom: $Space-4 !important;
  }
}
