$nav-height: 60px;
$mobile-nav-height: 116px;

$sidebar-width: 10rem;

$card-banner-height: 250px;
$card-promo-position: 195px;

$lg-footer-height: 93px;
$md-footer-height: 181px;
$sm-footer-height: 246px;
$xs-footer-height: 390px;
$new-footer-height: 128px;
$topbar-height: 64px;
$breadcrumbs-height: 52px;

$sidebar-collapsed-width: 5.5rem;
$sidebar-expanded-width: 14rem;
$sidebat-item-height: 43px;

$Space-1: 4px;
$Space-2: 8px;
$Space-3: 16px;
$Space-4: 24px;
$Space-5: 32px;
$Space-6: 40px;
$Space-7: 48px;
$Space-8: 56px;
$Space-9: 64px;
$Space-10: 72px;
$Space-11: 80px;
$Space-12: 88px;
$Space-13: 96px;
$Space-14: 104px;
$Space-15: 112px;
