/*
COLOR SCHEME @todo: Pegar todos os schemes
*/

// Edupass
$edupass-purple: #7e0b80;
$edupass-light-purple: #965f96;
$edupass-dark-purple: #370037;

$edupass-dark-purple-opacity: #37003765;

$edupass-black: #000000;

$edupass-black-10: #0000001a;
$edupass-black-50: #00000080;

$edupass-dark-gray: #3a3a3a;

$edupass-ice: #f8fafe;

// Gray scale
$edupass-white: #ffffff;
$gray-10: #f4f4f4;
$gray-20: #e0e0e0;
$gray-30: #c6c6c6;
$gray-40: #a8a8a8;
$gray-50: #8d8d8d;
$gray-60: #6f6f6f;
$gray-70: #525252;
$gray-75: #4d4d4d;
$gray-80: #383838;
$gray-90: #252525;
$gray-100: #161616;

$gray-0-40: #ffffff66;
$gray-0-70: #ffffffb3;

// Status
$edupass-price: #496b3d;
$edupass-success: #3ab572;
$edupass-success-light: #9cd9b8;
$edupass-yellow: #ffc107;
$edupass-erro: #ff0d0f;
$edupass-light-yellow: #fada7d;
$edupass-price: #496b3d;
$edupass-success-ligh: #9cd9b8;

// Actions
$botao-hover: #a3649e;
