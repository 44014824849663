.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
  font-size: 12px;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-accent .mat-slider-thumb {
  background-color: #7e0b80;
}

.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: $edupass-purple;
}

.mdc-slider .mdc-slider__track {
  height: 2px !important;
  background-color: $gray-30;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: $gray-20 !important;
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: $edupass-purple !important;
}

.mat-mdc-slider .mdc-slider__thumb-label {
  background-color: $edupass-purple;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: $edupass-purple !important;
  border-color: $edupass-purple !important;
}
