@keyframes skeleton {
  0% {
    transform: translateX(-102%);
    opacity: 0.3;
  }
  50% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(-102%);
    opacity: 0.3;
  }
}
