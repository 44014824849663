@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'colors';

@mixin font(
  $fontSize: medium,
  $fontWeight: medium,
  $lineHeight: normal,
  $color: $edupass-black
) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-family: 'Assistant', sans-serif;
  line-height: $lineHeight;
  color: $color;
}

.Headline-1 {
  @include font(48px, 600, 63px);

  &-Bold {
    @extend .Headline-1;
    font-weight: 700;
  }
}

.Headline-2 {
  @include font(36px, 600, 47px);

  &-Bold {
    @extend .Headline-2;
    font-weight: 700;
  }
}

.Subtitle-1 {
  @include font(32px, 300, 42px);

  &-ExtraLight {
    @extend .Subtitle-1;
    font-weight: 200;
  }
}

.Subtitle-2 {
  @include font(24px, 600, 31px);

  &-Regular {
    @extend .Subtitle-2;
    font-weight: 400;
  }

  &-Bold {
    @extend .Subtitle-2;
    font-weight: 700;
  }
}

.BodyText-1 {
  @include font(16px, 400, 21px);

  &-SemiBold {
    @extend .BodyText-1;
    font-weight: 600;
  }

  &-Bold {
    @extend .BodyText-1;
    font-weight: 700;
  }
}

.BodyText-2 {
  @include font(14px, 400, 18px);

  &-SemiBold {
    @extend .BodyText-2;
    font-weight: 600;
  }

  &-Bold {
    @extend .BodyText-2;
    font-weight: 700;
  }
}

.ButtonText {
  @include font(16px, 600, 21px);

  &-Bold {
    @extend .ButtonText;
    font-weight: 700;
  }
}

.FeedbackText {
  @include font(10px, 700, 13.1px);
}
